import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { SwiperOptions } from "swiper/types/swiper-options"
import clsx from "clsx"
import SliderNav from "../Assets/SliderNav/SliderNav"
import useWindowSize from "../../hooks/useWindowSize"
import { useEffect, useMemo, useState } from "react"
import { useGetImportantEventsQuery } from "../../redux/api/important-events"
import { getApiUrl } from "../../utils/getApiUrl"

import "swiper/css"
import styles from "./ImportantEvents.module.scss"
import { IImportantEvent } from "../../types/content"
import EventsModal from "../Modals/EventsModal/EventsModal"

const ImportantEvents = () => {
  const { isDesktop } = useWindowSize()
  const [events, setEvents] = useState<IImportantEvent[]>([])
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [startSlide, setStartSlide] = useState<number | undefined>(undefined)

  const { data, isLoading } = useGetImportantEventsQuery()

  const swiperParams: SwiperOptions = useMemo(() => {
    return {
      slidesPerView: isDesktop ? 5 : 3,
      spaceBetween: isDesktop ? 15 : 8,
      modules: [Navigation],
      navigation: {
        prevEl: `.${styles.nav} .slider-prev-btn`,
        nextEl: `.${styles.nav} .slider-next-btn`,
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
    }
  }, [isDesktop])

  useEffect(() => {
    if (!data?.aEvents) return
    const clone = [...data.aEvents]
    const dataSorted = clone
      .sort((a, b) => {
        return b.force_view === a.force_view ? 0 : b.force_view ? 1 : -1
      })
      .sort((a, b) => {
        return a.isViewed === b.isViewed ? 0 : a.isViewed ? 1 : -1
      })
    setEvents(dataSorted)
    const index = dataSorted.findIndex((event) => event.force_view && !event.isViewed)
    if (index < 0) return
    setStartSlide(index)
    setModalOpen(true)
  }, [data?.aEvents])

  const slides = useMemo(() => {
    if (!events?.length) return null
    return events.map((event, index) => {
      return (
        <SwiperSlide
          onClick={() => handleClick(index)}
          key={event.id}
          className={clsx(styles.slide, !event.isViewed && styles["slide--is-active"])}
        >
          <div
            className={styles.slide__inner}
            style={{
              backgroundImage: `url(${
                event.image_id ? getApiUrl() + "/public/photo/preview/" + event.image_id : "/img/events-template.jpg"
              })`,
            }}
          >
            <h5 className={styles.slide__title}>{event.name}</h5>
          </div>
        </SwiperSlide>
      )
    })
  }, [events])

  const handleClick = (index: number) => {
    setStartSlide(index)
    setModalOpen(true)
  }

  return (
    <section className={styles.wrap}>
      {isLoading ? (
        <div className={styles.loaderWrap}>
          <div className={clsx("skeletonBlock", styles.itemLoader)} />
          <div className={clsx("skeletonBlock", styles.itemLoader)} />
          <div className={clsx("skeletonBlock", styles.itemLoader)} />
          {isDesktop && (
            <>
              <div className={clsx("skeletonBlock", styles.itemLoader)} />
              <div className={clsx("skeletonBlock", styles.itemLoader)} />
            </>
          )}
        </div>
      ) : (
        <>
          <Swiper {...swiperParams} className={styles.slider}>
            {slides}
          </Swiper>
          <SliderNav className={styles.nav} />
        </>
      )}
      {isModalOpen && (
        <EventsModal
          open={isModalOpen}
          setOpen={setModalOpen}
          events={events}
          setEvents={setEvents}
          startSlide={startSlide}
        />
      )}
    </section>
  )
}

export default ImportantEvents
