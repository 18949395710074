import { useEffect, useRef, useState } from "react"
import { useGetProductInfoQuery, useGetCategoriesQuery } from "../../redux/api/main"
import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import { toBase64, url2File } from "../../utils/helpers"
import { Form, Formik } from "formik"
import OrderServiceForm from "./OrderServiceForm"
import { authModalOpen } from "../../redux/slice/authModal"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  useDeleteDraftOrderMutation,
  useGetDraftOrderMutation,
  usePostCreateOrderMutation,
} from "../../redux/api/content"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import clsx from "clsx"
import orderServiceStyles from "./OrderService.module.scss"
import styles from "./OrderService.module.scss"
import ArticlesMaybe from "../ArticlesMaybe/ArticlesMaybe"
import useWindowSize from "../../hooks/useWindowSize"
import OrderModal from "../Modals/OrderModal/OrderModal"
import { setCookie } from "../../utils/cookies"
import { SAVED_SERVICE_COOKIE } from "../../utils/constants"
import moment from "moment"
import { useScrollBlock } from "../../hooks/useScrollBlock"

interface IOption {
  alias?: string
  conditions?: any[]
  id?: string
  name?: string
  type?: string
}

const OrderService = ({ id, categoryId }: { id: string; categoryId: string }) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { object_id } = useParams()
  const navigate = useNavigate()
  const localSavedValues = localStorage.getItem("savedOrder")
  const { allowScroll } = useScrollBlock()

  const isObjectPage = pathname.includes("object")

  const { user } = useAppSelector((state) => state.auth)

  const { data, isLoading: isLoadingProduct } = useGetProductInfoQuery(id)
  const { data: categories, isLoading: isLoadingList } = useGetCategoriesQuery()
  const [createOrder, { isLoading: isCreating }] = usePostCreateOrderMutation()
  const [getDraftOrder] = useGetDraftOrderMutation()
  const [deleteDraftOrder] = useDeleteDraftOrderMutation()

  const [validateIds, setValidateIds] = useState<any[]>([])
  const draftValues = useRef<any[]>([])
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [orderModal, setOrderModal] = useState(false)
  // Страница конкретного объекта
  // const isObjectPage = pathname.includes("object")
  const [initialValuesForm, setInitialValuesForm] = useState<{ [key: string]: any }>({})
  const { isDesktop } = useWindowSize()

  const newInitVal: { [key: string]: any } = {}
  const createInitialValues = async (options: any) => {
    const parsedFunc = async () => {
      const idsTypeAttachment = options.filter((option: any) => option.type === "attachment").map((el: any) => el.id)

      let parsedValues: any = {}

      const base64ToBlob = async (files: { file: string; fileName: string }[]) => {
        return await Promise.all(files.map((item: any) => url2File(item.file, `${item.fileName}`)))
      }

      if (draftValues.current.length > 0) {
        await Promise.all(
          draftValues.current.map(async (draftEl: any) => {
            if (idsTypeAttachment.length > 0 && idsTypeAttachment.includes(draftEl.id)) {
              parsedValues[draftEl.id] = await base64ToBlob(draftEl.data)
            } else {
              parsedValues[draftEl.id] = draftEl.data
            }
          }),
        )
      } else {
        parsedValues = localSavedValues
          ? JSON.parse(localSavedValues).find(
              (el: any) => el?.service_id === id && el.technical_object_id === object_id,
            )
          : undefined
      }

      // if (parsedValues)
      //   for (const key in parsedValues) {
      //     // Проверка на поле с фото/файлами
      //     if (parsedValues[key]?.files && parsedValues[key].files.length > 0) {
      //       const decodedFiles: any[] = []

      //       await Promise.all(
      //         parsedValues[key].files.map(async (item: any) => {
      //           const file = await url2File(item.file, `${item.name}`)
      //           decodedFiles.push(file)
      //         }),
      //       )

      //       parsedValues[key] = decodedFiles
      //     }
      //   }

      return parsedValues
    }

    const parsedLocalSavedValues: any = await parsedFunc()

    options &&
      options.forEach((option: any, i: number) => {
        if (option.type === "text") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ""
        if (option.type === "boolean") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "checkbox") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "number") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "datetime") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? undefined
        if (option.type === "list") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "photo") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "attachment") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "information")
          newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ["information", 0]
        if (option.type === "directory")
          newInitVal[`directory-${option.id}`] =
            parsedLocalSavedValues?.[`directory-${option.id}`] || parsedLocalSavedValues?.[`${option.id}`] || []
        if (option.type === "qr") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ""

        if (option.conditions.length > 0) {
          option.conditions.forEach((condition: any) => createInitialValues(condition.options))
        }
      })

    newInitVal.service_id = data?.id
    newInitVal.count = 1

    const isHaveTechObj = data?.technicalObjects
    if (isHaveTechObj) {
      newInitVal["technical_object_id"] = isObjectPage ? object_id : undefined
    }
    // if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    // if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = object_id

    setInitialValuesForm(newInitVal)
    // return newInitVal
  }

  useEffect(() => {
    if (!localSavedValues) {
      localStorage.setItem("savedOrder", JSON.stringify([]))
    }
  }, [])

  useEffect(() => {
    // if (data?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    getDraftOrder({ id: id, technical_object_id: object_id || "" }).then((resp: any) => {
      draftValues.current = resp?.data?.data?.options
      createInitialValues(data?.options)
    })
  }, [data])

  useEffect(() => {
    if (!isDesktop) setOrderModal(true)
  }, [data, isDesktop])

  useEffect(() => {
    const endLinkLife = moment().add(2, "h")

    setCookie(SAVED_SERVICE_COOKIE, JSON.stringify({ order_id: id, category_id: categoryId, object_id }), {
      expires: new Date(endLinkLife.toDate()),
    })
  }, [id, object_id])

  const deepFindNeedOption = (parentArr: IOption[], searchedId: string): IOption | null => {
    const needEl = parentArr.find((el) => el.id === searchedId)
    let isFind = false
    if (needEl) return needEl

    let result: IOption | null = null
    parentArr.forEach((ell) => {
      if (ell?.conditions?.length) {
        ell?.conditions?.forEach((elll: any) => {
          if (!isFind) {
            result = deepFindNeedOption(elll.options, searchedId)
            if (result) isFind = true
          }
        })
      }
    })
    return result
  }
  const validateOpt = (values: any) => {
    const errors: { [p: string]: string } = {}
    const boolTypes = ["boolean"]
    const arrTypes = ["list", "attachment", "photo"]

    // Проверка на новую загрузку файлов при создании заявки
    Object.keys(values).forEach((el: string) => {
      if (Array.isArray(values[el]) && values[el].find((val: string) => val === "loading")) {
        errors[el] = "loading"
      }
    })

    validateIds.forEach((el: any) => {
      if (boolTypes.includes(el.type)) {
        if (values?.[el.id] === null) errors[el.id] = "empty"
      } else if (arrTypes.includes(el.type) && !values?.[el.id]?.length) {
        errors[el.id] = "empty"
      } else if (el.type === "directory") {
        if (!values?.[`directory-${el.id}`]?.length) {
          errors[`directory-${el.id}`] = "empty"
        }
      } else if (!values?.[el.id]) {
        errors[el.id] = "empty"
      }
      // if (values?.[el.id]?.length === 0 || !values?.[el.id]) errors[el.id] = "empty"
    })
    if (data?.technicalObjects && data.technicalObjects.length > 0 && !values?.technical_object_id) {
      errors.technical_object_id = "selectObj"
    }

    return errors
  }

  const submitForm = async (values: { [key: string]: any }, actions: any) => {
    if (!data?.service_action_name) return
    const formData = new FormData()
    let key: keyof typeof values
    let arrNum = 0

    for (key in values) {
      if (
        key !== "count" &&
        key !== "service_id" &&
        key !== "technical_object_id" &&
        key !== "technical_object_name" &&
        !key.includes("directory-")
      ) {
        if (Array.isArray(values[key]) && values[key].length && values[key][0] !== "information") {
          let numArr = 0
          for (const item of values[key]) {
            formData.append(`options[${arrNum}][id]`, key as string)

            const fileName = item?.name ?? ""
            const attachmentType = deepFindNeedOption(data.options, key)?.type
            if (item instanceof Blob && data?.options) {
              const file = await toBase64(item)
              //@ts-ignore
              formData.append(
                `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}]${
                  attachmentType === "attachment" ? "[data]" : ""
                }`,
                file as any,
              )
              if (attachmentType === "attachment") {
                formData.append(
                  `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}][filename]`,
                  fileName,
                )
              }
            } else {
              if (attachmentType === "photo") {
                formData.append(`options[${arrNum}][images][${numArr}]`, item)
              } else {
                formData.append(`options[${arrNum}][items][${numArr}]`, item)
              }
            }
            numArr++
          }
          arrNum++
        } else if (Array.isArray(values[key]) && values[key][0] && values[key][0] === "information") {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][wasShowed]`, values[key][1])
          arrNum++
        } else if (typeof values[key] === "boolean" || (!Array.isArray(values[key]) && values[key])) {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][value]`, values[key])
          arrNum++
        }
      }
      if (key.includes("directory-")) {
        formData.append(`options[${arrNum}][id]`, key.replace("directory-", ""))
        for (let i = 0; i < values[key].length; i++) {
          formData.append(`options[${arrNum}][objects][]`, values[key][i].id)
        }
      }
    }

    formData.append("service_id", values.service_id)
    formData.append("count", `${values.count}`)
    // if (data?.technicalObjects?.length === 1 && data?.technicalObjects?.[0]?.children_count) {
    //   formData.append("technical_object_id", `${data?.technicalObjects[0]?.id}`)
    // }
    if (values.technical_object_id) {
      formData.append("technical_object_id", `${values.technical_object_id}`)
    }
    // else if (isObjectPage) {
    //   formData.append("technical_object_id", `${data?.id}`)
    // }
    // formData.forEach((val, key) => {
    //   console.log(key + ":" + val)
    // })
    // return

    if (user?.id) {
      actions.setSubmitting(true)
      setLoadingSubmit(true)
      try {
        await createOrder(formData)
          .unwrap()
          .then((res) => {
            actions.resetForm()
            actions.setSubmitting(false)
            setLoadingSubmit(false)
            deleteDraftOrder({ id: id, technical_object_id: object_id || "" })

            if (localSavedValues)
              localStorage.setItem(
                "savedOrder",
                JSON.stringify(
                  JSON.parse(localSavedValues).filter(
                    (el: any) => !(el?.service_id === id && el.technical_object_id === values.technical_object_id),
                  ),
                ),
              )

            setCookie(SAVED_SERVICE_COOKIE, "null", {
              expires: new Date(0),
            })

            navigate(`/order/${res.data.id}`)
          })
      } catch (e) {
        console.warn(e)
        actions.setSubmitting(false)
        setLoadingSubmit(false)
      }
    } else {
      dispatch(authModalOpen({ flag: true }))
    }
    if (!isDesktop) allowScroll(true)
  }

  return (
    <>
      {isLoadingProduct || isLoadingList ? (
        <div className={clsx("skeletonBlock", orderServiceStyles.linkLoader)} />
      ) : (
        <LocalizedLink
          to={object_id ? `/object/${object_id}/category/${categoryId}` : `/category/${categoryId}`}
          className={styles.link}
        >
          <ArrowLeftBoldIcon />
          {categories?.find((category) => category.id === categoryId)?.name}
        </LocalizedLink>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => {
          void submitForm(values, actions)
        }}
        validate={validateOpt}
      >
        {() => (
          <Form>
            <>
              {isDesktop ? (
                <>
                  {isLoadingProduct ? (
                    <div className={clsx("skeletonBlock", styles.bodyLoading)} />
                  ) : (
                    data && (
                      <OrderServiceForm data={data} isLoadingSubmit={isLoadingSubmit} setValidateIds={setValidateIds} />
                    )
                  )}
                </>
              ) : (
                <>
                  {data && (
                    <OrderModal
                      open={orderModal}
                      setOpen={setOrderModal}
                      modalInfo={data}
                      setInitialValuesForm={setInitialValuesForm}
                      isLoading={isCreating}
                      orderModalLoading={isLoadingProduct}
                      setValidateIds={setValidateIds}
                    />
                  )}
                </>
              )}
            </>
          </Form>
        )}
      </Formik>

      <ArticlesMaybe serviceID={id} />
    </>
  )
}

export default OrderService
