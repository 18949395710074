import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./OrderModal.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import OrderModalBlock from "./OrderModalBlock"
import CheckBoxRow from "../../Assets/CheckBoxRow/CheckBoxRow"
import { useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useDropzone } from "react-dropzone"
import { SwiperOptions } from "swiper/types/swiper-options"
import { useTranslation } from "react-i18next"
import { IOrder } from "../../../types/orderTypes"
import { NumericFormat } from "react-number-format"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import SwipeLine from "../../Modal/SwipeLine"
import { declensionNumber, getFileIco, timeUnitsForLang, toBase64, url2File } from "../../../utils/helpers"
import OrderModalSkeleton from "./OrderModalSkeleton"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import FileDropzone from "../../Assets/FileDropzone/FileDropzone"
import { useAppSelector } from "../../../hooks"
import OrderModalPhotoBlock from "./OrderModalPhotoBlock"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import OptionsList from "./OptionsList"
import { getApiUrl } from "../../../utils/getApiUrl"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import TitleBack from "../../TitleBack/TitleBack"
import Button from "../../Assets/Button/Button"
import Label from "../../Assets/Label/Label"
import { useGetTechObjectQuery } from "../../../redux/api/main"
import { getLocale } from "../../../utils/getLocale"
import ArticlesMaybe from "../../ArticlesMaybe/ArticlesMaybe"
import _debounce from "lodash/debounce"
import * as Sentry from "@sentry/react"
import { useGetDraftOrderMutation, useSetDraftOrderMutation } from "../../../redux/api/content"
import { deepDiff } from "../../../utils/deepDiff"
import { isEqual } from "lodash"
import SelectObject from "../../SelectObject/SelectObject"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalInfo: IOrder
  setInitialValuesForm: React.Dispatch<React.SetStateAction<any>>
  isLoading: boolean
  orderModalLoading?: boolean
  setValidateIds: any
}

const OrderModal: React.FC<Props> = ({
  open,
  setOpen,
  modalInfo,
  setInitialValuesForm,
  isLoading,
  orderModalLoading,
  setValidateIds,
}) => {
  const navigate = useNavigate()
  const swipedModal: any = useRef(null)
  const [isHaveTechObj, setIsHaveTechObj] = useState(false)
  const [isError, setIsError] = useState(false)
  const { values, submitForm, setFieldValue, isSubmitting, errors, isValidating, touched, setFieldTouched } =
    useFormikContext<{
      [key: string]: any
    }>()
  const ref = useRef<HTMLDivElement>(null)
  const { object_id, category_id } = useParams()
  const draftValues = useRef<any[]>([])
  const localSavedValues = localStorage.getItem("savedOrder")
  const [getDraftOrder] = useGetDraftOrderMutation()
  const [setDraftOrder] = useSetDraftOrderMutation()
  const [initialObjName, setInitialObjName] = useState<string>("")

  const { data: techObjData } = useGetTechObjectQuery(object_id as string, { skip: !object_id })

  const firstTime = modalInfo.execution_time_from
  const secondTime = modalInfo.execution_time_to

  const object = useAppSelector((state) => state.object.technicalObject)
  // console.log(useFormikContext().values, useFormikContext().errors);

  const currentLang = getLocale()
  let currentTimeUnit =
    timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
      modalInfo.execution_time_units as "minutes" | "hours" | "days"
    ]

  if (currentLang === "ru") {
    if (modalInfo.execution_time_units === "hours") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["час", "часа", "часов"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["часа", "часов", "часов"])
      }
    }
    if (modalInfo.execution_time_units === "days") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["день", "дня", "дней"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
      }
    }
  }

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const errorsEls = ref.current?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting, ref])

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  // Инпут файл
  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFieldValue(`${inputRef.current.name}`, [
        ...values[inputRef.current.name],
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
  })

  // Превьюшки
  const thumbs = (id: string) =>
    values[id]?.map((file: any) => (
      <div className={styles.photoBlock__preview} key={file.name}>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          onClick={() =>
            setFieldValue(
              id,
              values[id].filter((el: any) => el.path !== file.path),
            )
          }
          className={styles.photoBlock__close}
        >
          <img src="/img/close.svg" alt="" />
        </button>
      </div>
    ))

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    modules: [Pagination],
    pagination: {
      type: "bullets",
      clickable: true,
    },
  }

  const { pathname } = useLocation()
  const params = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  const prevModalInfo = useRef(modalInfo)

  useEffect(() => {
    if (modalInfo?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    if (modalInfo.id && !isEqual(prevModalInfo.current, modalInfo)) {
      prevModalInfo.current = modalInfo
      getDraftOrder({ id: modalInfo.id, technical_object_id: object_id || "" }).then((resp: any) => {
        draftValues.current = resp?.data?.data?.options
        createInitialValues(modalInfo?.options)
      })
    }
  }, [modalInfo])

  //Костыль добавления этих полей при открытии модалки
  useEffect(() => {
    if (open) {
      setFieldValue("service_id", modalInfo.id)
      setFieldValue("count", 1)
    }
  }, [open])

  const newInitVal: { [key: string]: any } = {}

  const refOldValues = useRef(values)

  const debounceFn = useCallback(
    _debounce(async (values: any) => {
      if (Object.keys(refOldValues.current).length === Object.keys(values).length) {
        const diffValue = deepDiff(refOldValues.current, values)

        if (diffValue.length > 0) {
          let files: any = diffValue[0].data[0] instanceof File ? [] : undefined
          if (files) {
            files = await Promise.all(
              diffValue[0].data.map(async (file: File) => {
                return { file: await toBase64(file), fileName: file.name }
              }),
            )
            diffValue[0].data = files
          }
          setDraftOrder({
            id: values.service_id,
            data: {
              options: diffValue,
              technical_object_id: values?.technical_object_id || "",
            },
          })
        }

        if (localSavedValues) {
          const changeOrderFilesType = async () => {
            const newValues: { [key: string]: any } = {}

            for (const key in values) {
              // Проверка на поле с фото/файлами
              // if (
              //   Array.isArray(values[key]) &&
              //   values[key].length > 0 &&
              //   (values[key][0].size || values[key][0].base64)
              // ) {
              //   const withoutBigFiles: any[] = values[key].filter((file: any) => file.size < 250000)
              //   const newFiles: any[] = []

              //   await Promise.all(
              //     withoutBigFiles.map(async (item: any) => {
              //       const file = await toBase64(item)
              //       newFiles.push({ file, name: item.name })
              //     }),
              //   )

              //   // Добавил ключ files, что бы из стореджа понимать что это файлы
              //   newValues[key] = { files: newFiles }
              // } else {
              newValues[key] = values[key]
              // }
            }
            return newValues
          }

          const changesValues = await changeOrderFilesType()

          const parsedLocalSavedValues: any[] = JSON.parse(localSavedValues)
          const ordrIndex = parsedLocalSavedValues.findIndex(
            (el) => el.service_id === values.service_id && el.technical_object_id === values.technical_object_id,
          )

          if (ordrIndex > -1) {
            parsedLocalSavedValues[ordrIndex] = changesValues
          } else {
            parsedLocalSavedValues.push(changesValues)

            if (parsedLocalSavedValues.length > 4) parsedLocalSavedValues.shift()
          }

          try {
            localStorage.setItem("savedOrder", JSON.stringify(parsedLocalSavedValues))
          } catch (err) {
            Sentry.captureException(Error("Ошибка записи в Localstore"))
          }
        }
      }

      refOldValues.current = values
    }, 500),
    [localSavedValues],
  )

  useEffect(() => {
    if (!localSavedValues) {
      localStorage.setItem("savedOrder", JSON.stringify([]))
    }
    if (values.service_id) debounceFn(values)
  }, [values])

  const createInitialValues = async (options: any) => {
    const parsedFunc = async () => {
      const idsTypeAttachment = options.filter((option: any) => option.type === "attachment").map((el: any) => el.id)

      let parsedValues: any = {}

      const base64ToBlob = async (files: { file: string; fileName: string }[]) => {
        return await Promise.all(files.map((item: any) => url2File(item.file, `${item.fileName}`)))
      }

      if (draftValues.current.length > 0) {
        await Promise.all(
          draftValues.current.map(async (draftEl: any) => {
            if (idsTypeAttachment.length > 0 && idsTypeAttachment.includes(draftEl.id)) {
              parsedValues[draftEl.id] = await base64ToBlob(draftEl.data)
            } else {
              parsedValues[draftEl.id] = draftEl.data
            }
          }),
        )
      } else {
        parsedValues = localSavedValues
          ? JSON.parse(localSavedValues).find(
              (el: any) => el?.service_id === modalInfo.id && el.technical_object_id === object_id,
            )
          : undefined
      }

      // if (parsedValues)
      //   for (const key in parsedValues) {
      //     // Проверка на поле с фото/файлами
      //     if (parsedValues[key]?.files && parsedValues[key].files.length > 0) {
      //       const decodedFiles: any[] = []

      //       await Promise.all(
      //         parsedValues[key].files.map(async (item: any) => {
      //           const file = await url2File(item.file, `${item.name}`)
      //           decodedFiles.push(file)
      //         }),
      //       )

      //       parsedValues[key] = decodedFiles
      //     }
      //   }
      return parsedValues
    }

    const parsedLocalSavedValues: any = await parsedFunc()

    options &&
      options.forEach((option: any, i: number) => {
        if (option.type === "text") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ""
        if (option.type === "boolean") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "checkbox") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "number") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? null
        if (option.type === "datetime") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? undefined
        if (option.type === "list") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "photo") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "attachment") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? []
        if (option.type === "information")
          newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ["information", 0]
        if (option.type === "directory")
          newInitVal[`directory-${option.id}`] =
            parsedLocalSavedValues?.[`directory-${option.id}`] || parsedLocalSavedValues?.[`${option.id}`] || []
        if (option.type === "qr") newInitVal[option.id] = parsedLocalSavedValues?.[option.id] ?? ""

        if (option.conditions.length > 0) {
          option.conditions.forEach((condition: any) => createInitialValues(condition.options))
        }
      })

    newInitVal.service_id = modalInfo.id
    newInitVal.count = 1

    // if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    // if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = params?.object_id

    const isHaveTechObj = modalInfo?.technicalObjects
    if (isHaveTechObj) {
      newInitVal["technical_object_id"] = isObjectPage ? object_id : undefined
    }

    setInitialValuesForm(newInitVal)
    // return newInitVal
  }

  const nextOrSubmitStep = () => {
    // const bodyModal = document.getElementById("orderModalContent")
    // const errorsEls = bodyModal?.querySelector(".error")
    // errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })

    // console.log(errorsEls, errors);

    submitForm()
    if (localSavedValues)
      localStorage.setItem(
        "savedOrder",
        JSON.stringify(
          JSON.parse(localSavedValues).filter(
            (el: any) => !(el?.service_id === modalInfo.id && el.technical_object_id === values.technical_object_id),
          ),
        ),
      )
  }

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  useEffect(() => {
    if (!object_id || !techObjData?.name) return
    setInitialObjName(techObjData.name)
    void setFieldTouched("technical_object_id")
    void setFieldValue("technical_object_id", object_id)
  }, [object_id, techObjData])

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={clsx(styles.orderModal)}
      header={
        <>
          {!modalInfo.name || orderModalLoading ? (
            <div className={`selectBack ${styles.orderModal__sk4}`} />
          ) : (
            <>
              <TitleBack
                title={`${modalInfo?.name}`}
                onClick={() => {
                  setOpen(false)
                  const objectPrefix = object_id ? `/object/${object_id}` : ""
                  navigate(`${objectPrefix}/category/${category_id}`)
                }}
                noLinkBack
              />
            </>
          )}
        </>
      }
      isCloseBtn={false}
      name={"OrderModal"}
    >
      <div
        className={clsx(
          styles.orderModal__body,
          orderModalLoading && styles.orderModal__body_load,
          !orderModalLoading && !modalInfo?.options?.length && styles.orderModal__roundedTop,
        )}
        ref={ref}
      >
        {orderModalLoading ? (
          <OrderModalSkeleton />
        ) : (
          <>
            {!!modalInfo?.description && (
              <div
                className={styles[`modal-text`]}
                dangerouslySetInnerHTML={{ __html: modalInfo?.description ?? "" }}
              />
            )}

            {isLoading ? (
              <div className={"skeletonBlock"} style={{ height: "200px" }} />
            ) : (
              <>
                {(object_id || modalInfo?.technicalObjects?.length > 0) && (
                  <>
                    <SelectObject
                      className={styles.selectObject}
                      data={modalInfo}
                      initialName={initialObjName}
                      error={touched?.technical_object_id && errors?.technical_object_id}
                      callbackSubmit={(el) => {
                        if (!el) return
                        void setFieldTouched("technical_object_id")
                        void setFieldValue("technical_object_id", el.id)
                      }}
                    />
                  </>
                )}
              </>
            )}

            {modalInfo?.options?.length > 0 && (
              <OptionsList
                optionsItems={modalInfo?.options}
                setError={setErrorHandler}
                setValidateIds={setValidateIds}
              />
            )}
          </>
        )}

        {modalInfo.id && <ArticlesMaybe serviceID={modalInfo.id} />}
      </div>
      <div className={clsx(styles.orderModal__submitBlock, "submitBlock")}>
        {!orderModalLoading && (
          <>
            <div className={styles.orderModal__submitBlockHead}>
              <h5>{modalInfo?.name}</h5>
              {modalInfo.execution_time_from || modalInfo.execution_time_to ? (
                <Label
                  txt={(() => {
                    if (firstTime && secondTime) {
                      return `${firstTime}-${secondTime} ${currentTimeUnit}` as string
                    } else if (firstTime && !secondTime) {
                      return `${t("from")} ${firstTime} ${currentTimeUnit}` as string
                    } else if (!firstTime && secondTime) {
                      return `${t("to")} ${secondTime} ${currentTimeUnit}` as string
                    } else {
                      return ""
                    }
                  })()}
                  className={clsx(styles.orderModal__time)}
                  mode={"orange"}
                />
              ) : null}
            </div>

            <div className={styles.orderModal__submitBlockBottom}>
              {modalInfo?.is_multiorder ? (
                <div className={styles.orderModal__calc}>
                  <button
                    type="button"
                    className={styles.orderModal__calcPlus}
                    onClick={() => setFieldValue("count", values.count - 1)}
                    disabled={values.count < 2}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M12 8L4 8"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <div className={styles.orderModal__calcNum}>{values.count}</div>
                  <button
                    type="button"
                    className={styles.orderModal__calcPlus}
                    onClick={() => setFieldValue("count", values.count + 1)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M8 4L8 12"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8L4 8"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}
              {modalInfo?.service_action_name && (
                <Button
                  txt={modalInfo?.service_action_name}
                  onClick={nextOrSubmitStep}
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ModalPortal>
  )
}

export default OrderModal
