import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import styles from "./OrderModal.module.scss"
import OrderModalBlock from "./OrderModalBlock"
import { PhotoCameraIcon } from "../../../icons/PhotoCameraIcon"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { checkAllowCamera, toBase64 } from "../../../utils/helpers"
import { setIsAllowCameraModal } from "../../../redux/slice/modals"
import { useDispatch } from "react-redux"
import { getApiUrl } from "../../../utils/getApiUrl"
import axios from "axios"
import { compressImage } from "../../../utils/compressImage"
import { CompressIcon } from "../../../icons/CompressIcon"
import * as Sentry from "@sentry/react"

interface Props {
  el: any
  setFieldValue: any
  values: any
  isRequired?: boolean
  error?: boolean
  isCamera: boolean
}

const OrderModalPhotoBlock: React.FC<Props> = ({ el, setFieldValue, values, isRequired, error, isCamera }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const siteUrl = getApiUrl()

  const [isErrorLoadImage, setErrorLoadImage] = useState<boolean>(false)
  const [isErrorMes, setIsErrorMes] = useState<string>("")

  const [photoArray, setPhotoArrayArray] = useState<any[]>(values[el.id] ?? [])
  const [isCompressLoading, setCompressLoading] = useState<boolean>(false)

  useEffect(() => {
    setCompressLoading(photoArray?.some((photo) => photo?.status === "compressing"))
  }, [photoArray])

  useEffect(() => {
    if (
      values[el.id] &&
      photoArray.length !== values[el.id].length &&
      photoArray.every((photo) => typeof photo === "string" || !!photo.id)
    ) {
      setFieldValue(
        el.id,
        photoArray.map((photo) => (typeof photo === "string" ? photo : photo.id)),
      )
      if (inputRef && inputRef?.current) {
        inputRef.current.value = ""
      }
    }
  }, [photoArray])

  useEffect(() => {
    if (values[el.id] && values[el.id].length > 0 && photoArray.length === 0) {
      setPhotoArrayArray(values[el.id])
    }
  }, [values[el.id]])

  const dropzoneAccept = useMemo(() => {
    return !isCamera
      ? {
          accept: {
            "image/jpeg": [],
            "image/png": [],
          },
        }
      : undefined
  }, [isCamera])

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    ...dropzoneAccept,
    onDrop: async (acceptedFiles) => {
      const acceptTypes = ["image/jpeg", "image/png"]

      if (!acceptedFiles.length || !acceptTypes.includes(acceptedFiles[0].type)) {
        setIsErrorMes(t("onlyJpgPng"))
      } else {
        const arrLoaded: any = acceptedFiles.map((file) => {
          return { id: null, name: file.name, precent: 0, status: "compressing", file: file }
        })

        setPhotoArrayArray((prev) => [...prev, ...arrLoaded])

        acceptedFiles.map(async (file: any) => {
          let progressStep = 0

          // const compressedFile =
          //   (await compressImage(file, {
          //     base64: true,
          //     onProgress: (compressPrecent) => {
          //       setPhotoArrayArray((prev) => {
          //         return prev.map((el) => {
          //           if (el.name && el.name === file.name) {
          //             return {
          //               ...el,
          //               precent: compressPrecent,
          //               status: "compressing",
          //             }
          //           }
          //           return el
          //         })
          //       })
          //     },
          //   })) || (await toBase64(file))
          const compressedFile = (await compressImage(file)) || (await toBase64(file))

          const reqData = { name: file.name, file: compressedFile }

          axios
            .post(`${siteUrl}/public/photo`, reqData, {
              withCredentials: true,
              onUploadProgress: (upload) => {
                if (upload?.total) {
                  const uploadloadProgress = Math.round((100 * upload.loaded) / upload.total)

                  if ((progressStep < 100 && uploadloadProgress - progressStep > 15) || uploadloadProgress === 100) {
                    progressStep = uploadloadProgress

                    setPhotoArrayArray((prev) => {
                      return prev.map((el) => {
                        if (el.name && el.name === file.name) {
                          return {
                            ...el,
                            precent: uploadloadProgress,
                            status: uploadloadProgress < 100 ? "loading" : "loaded",
                          }
                        }
                        return el
                      })
                    })
                  }
                }
              },
            })
            .then((res) => {
              setPhotoArrayArray((prev) => {
                return prev.map((el) => {
                  if (el.name && el.name === file.name) {
                    return { ...el, name: res.data.photoId, precent: 100, status: "loaded", id: res.data.photoId }
                  }
                  return el
                })
              })
              if (isErrorMes) setIsErrorMes("")
            })
            .catch((err) => {
              console.error("error in /public/photo: ", err)
              setPhotoArrayArray((prev) => prev.filter((el) => (typeof el === "string" ? true : el.name !== file.name)))
              const inp = inputRef.current as HTMLInputElement
              if (inp) inp.value = ""
              Sentry.captureException(Error(`Ошибка при загрузки фото. ${err}`))
              if (isCompressLoading) setCompressLoading(false)
              setIsErrorMes(t("problemsWithInternet"))
            })
        })
      }
    },
  })

  // Превьюшки
  const thumbs = useCallback(
    (id: string) => (
      <>
        {photoArray?.map((file: any, index: number) => {
          if (!file) return
          // fileId - Либо строка с ID с бека, либо объект для создания
          const fileId = typeof file === "string" ? file : file.id ?? undefined

          if (file.status === "compressing" || file.status === "loading" || (file.status === "loaded" && !fileId)) {
            const preview = URL.createObjectURL(file.file)
            return (
              <div className={clsx(styles.photoBlock__preview, styles.photoBlock__loader)} key={file.name}>
                <div
                  className={styles.photoBlock__loader__overlay}
                  style={{ height: `${file.status === "compressing" ? 100 : 100 - file.precent}%` }}
                />
                {file.precent ? (
                  <div
                    className={styles.photoBlock__compressBlock}
                    style={{ justifyContent: file.status === "compressing" ? "space-between" : "center" }}
                  >
                    {file.status === "compressing" && <CompressIcon />}
                    <span className={styles.photoBlock__loader__precent}>{file.precent}%</span>
                  </div>
                ) : null}

                <img src={preview} alt="preview" />
              </div>
            )
          }

          const preview = file.file ? URL.createObjectURL(file.file) : getApiUrl() + "/public/photo/" + fileId

          return (
            <div className={styles.photoBlock__preview} key={`${fileId}`}>
              <img
                src={preview}
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(preview)
                }}
              />
              <button
                type="button"
                onClick={() => {
                  setPhotoArrayArray((prev) => {
                    return prev.filter((photo) => fileId !== (typeof photo === "string" ? photo : photo.id))
                  })
                  if (isErrorMes) setIsErrorMes("")
                }}
                className={styles.photoBlock__close}
              >
                <img src="/img/close.svg" alt="" />
              </button>
            </div>
          )
        })}
      </>
    ),
    [photoArray],
  )

  const customInputProps = useMemo(() => {
    const newProps = { ...getInputProps() }

    if (isCamera) {
      newProps.onClick = (event: any) => {
        event.stopPropagation()
        event.preventDefault()
        handleInputClick()
      }
    }

    return newProps
  }, [isCamera, getInputProps])

  const handleInputClick = async () => {
    const { onClick: newClick } = getInputProps()

    checkAllowCamera()
      .then(() => {
        const input: any = document.getElementsByName(el.id)[0]

        input.onclick = (event: any) => {
          newClick(event)
        }

        input.click()
      })
      .catch(() => {
        const input: any = document.getElementsByName(el.id)[0]
        input.onclick = (event: any) => {
          newClick(event)
        }
        input.accept = "image/jpeg, image/png"
        input.click()
        dispatch(setIsAllowCameraModal(true))
      })
  }

  return (
    <OrderModalBlock title={`${el?.name}`} isRequired={isRequired}>
      {isCompressLoading && (
        <div className={styles.photoBlock__compressLoading}>
          <CompressIcon />
        </div>
      )}
      <div className={`${styles.photoBlock}`}>
        {photoArray.length ? thumbs(el.id) : null}
        <label {...getRootProps()} className={`${styles.photoBlock__append} ${error ? `${styles.error} error` : ""}`}>
          <input {...customInputProps} ref={inputRef} hidden name={el.id} />
          <PhotoCameraIcon className={styles.photoBlock__camera} />
          <div className={styles.photoBlock__plus}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="add_24px">
                <path
                  id="icon/content/add_24px"
                  d="M12.0002 8.66683H8.66683V12.0002C8.66683 12.3668 8.36683 12.6668 8.00016 12.6668C7.6335 12.6668 7.3335 12.3668 7.3335 12.0002V8.66683H4.00016C3.6335 8.66683 3.3335 8.36683 3.3335 8.00016C3.3335 7.6335 3.6335 7.3335 4.00016 7.3335H7.3335V4.00016C7.3335 3.6335 7.6335 3.3335 8.00016 3.3335C8.36683 3.3335 8.66683 3.6335 8.66683 4.00016V7.3335H12.0002C12.3668 7.3335 12.6668 7.6335 12.6668 8.00016C12.6668 8.36683 12.3668 8.66683 12.0002 8.66683Z"
                />
              </g>
            </svg>
          </div>
        </label>
      </div>
      {isErrorMes && <span className={clsx(styles["photoBlock__error"], "error-text")}>{isErrorMes}</span>}
    </OrderModalBlock>
  )
}

export default OrderModalPhotoBlock
