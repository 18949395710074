import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import styles from "./AuthModal2.module.scss"
import { NumberFormatBase, PatternFormat } from "react-number-format"
import { Dispatch, forwardRef, SetStateAction, useEffect, useRef, useState } from "react"
import {
  useGetCodeByEmailMutation,
  useGetSmsMutation,
  useLoginEmailMutation,
  useLoginSmsMutation,
} from "../../../redux/api/auth"
import Button from "../../Assets/Button/Button"
import { selectIsAllowForRegistaration } from "../../../redux/slice/auth"
import clsx from "clsx"
import { authModalOpen } from "../../../redux/slice/authModal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"

interface Props {
  seconds: number
  phoneNumber: string
  setStep: Dispatch<SetStateAction<number>>
  setOpen: Dispatch<SetStateAction<boolean>>
  setSeconds: Dispatch<SetStateAction<number>>
  isEmail: boolean
  email: string
}

const AuthModalCode = ({ seconds, phoneNumber, setStep, setOpen, setSeconds, isEmail, email }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useAppDispatch()
  const { allowScroll } = useScrollBlock()
  const inp2 = useRef<HTMLInputElement>(null)

  const isAllowForRegistration = useAppSelector(selectIsAllowForRegistaration)

  const [getSms, { isLoading }] = useGetSmsMutation()
  const [getCode, { isLoading: isLoadingCode }] = useGetCodeByEmailMutation()
  const [logined, { isLoading: isLoginLoading }] = useLoginSmsMutation()
  const [loginedEmail, { isLoading: isLoginLoadingEmail }] = useLoginEmailMutation()

  const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)
  const [isShowError, setIsShowError] = useState<boolean>(false)
  const [errorMessage, setErorMessage] = useState<"notFound" | "blocked" | undefined>(undefined)
  const [countWithErr, setCountWithErr] = useState(null)
  const [code, setCode] = useState<string | undefined>(undefined)
  const [isNeedOtp, setIsNeedOtp] = useState<boolean>(true)

  useEffect(() => {
    if ((isErrCode === 1 || isErrCode === 2) && code && code.length === 4) setIsErrCode(undefined)
    if (isErrCode === 3 && !seconds) setIsErrCode(undefined)
  }, [code])

  useEffect(() => {
    if (!inp2?.current) return
    inp2.current.focus()
  }, [])

  const checkCodeValueAndErrors = (e: any) => {
    if (isErrCode) {
      if (e.target.value.length < 4) {
        setIsShowError(false)
      }
    }
    setCode(e.target.value)
  }

  const loginWithCode = () => {
    if (isEmail && !email) return
    if (!isEmail && !phoneNumber) return
    if (isErrCode === 3) {
      if (isEmail) {
        getCodeByEmail()
      } else {
        getSmsByPhone()
      }
      setIsNeedOtp(true)
      setIsErrCode(undefined)
      return
    }
    if (!code || code.length !== 4) {
      setIsErrCode(1)
      setIsShowError(true)
      return
    }
    if (isErrCode) return
    if (isEmail) {
      loginedEmail({ email: email, code })
        .unwrap()
        .then((res) => {
          if (!res.errorCode) {
            if (isAllowForRegistration && res?.data?.user?.recently_created) {
              setStep(3)
            } else {
              setOpen(false)
              allowScroll(true)
              setTimeout(() => {
                dispatch(authModalOpen({ flag: false }))
                setStep(1)
              }, 0)
            }
          } else {
            if (res.errorCode === "InvalidCode") {
              setIsErrCode(2)
              setIsShowError(true)
              setCountWithErr(res.data.attemptsLeft)
            }
            if (res.errorCode === "LimitOfAttemptsIsExceeded") {
              setIsErrCode(3)
              setIsShowError(true)
              setSeconds(res?.data?.ttl || 0)
            }
            if (res.errorCode === "is_blocked") {
              setIsErrCode(5)
              setIsShowError(true)
            }
          }
        })
        .catch((err) => {
          console.error(err, "err")
        })
    } else {
      logined({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, ""), code })
        .unwrap()
        .then((res) => {
          if (!res.errorCode) {
            if (isAllowForRegistration && res?.data?.user?.recently_created) {
              setStep(3)
            } else {
              // if (appendFcn) appendFcn()
              setOpen(false)
              allowScroll(true)
              setTimeout(() => {
                dispatch(authModalOpen({ flag: false }))
                setStep(1)
              }, 0)
            }
          } else {
            if (res.errorCode === "InvalidCode") {
              setIsErrCode(2)
              setIsShowError(true)
              setCountWithErr(res.data.attemptsLeft)
            }
            if (res.errorCode === "LimitOfAttemptsIsExceeded") {
              setIsErrCode(3)
              setIsShowError(true)
              setSeconds(res?.data?.ttl || 0)
            }
            if (res.errorCode === "is_blocked") {
              setIsErrCode(5)
              setIsShowError(true)
            }
          }
        })
        .then(() => {
          // // Обновление списка услуг
          // dispatch(updateServiceList(serviceList?.data ?? []))
          // getCategories()
          //   .then((result: any) => {
          //     dispatch(updateServiceList({ list: result?.data?.data ?? [] }))
          //   })
          //   .catch((err: any) => console.log(err))
        })
        .catch((err) => {
          console.error(err, "err")
        })
    }
  }

  const getSmsByPhone = () => {
    // setLoading(true)
    setIsErrCode(undefined)
    getSms({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, "") })
      .unwrap()
      .then((resp: any) => {
        if (resp.errorCode === "UserSmsAuthNotFound") {
          // setStep(10)
          setErorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          // setStep(11)
          setErorMessage("blocked")
        } else {
          // if (resp.errorCode === "SmsWasSentToTheThisNumber") {
          //   setSeconds(resp?.data?.ttl)
          // }
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
        // setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        // setLoading(false)
      })
  }

  const getCodeByEmail = () => {
    setIsErrCode(undefined)
    getCode({ email: email })
      .unwrap()
      .then((resp) => {
        if (resp.errorCode === "UserEmailAuthNotFound") {
          setErorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          setErorMessage("blocked")
        } else if (resp.errorCode === "EmailWasSentToTheThisNumber") {
          setErorMessage("blocked")
        } else {
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (isNeedOtp) {
      if ("OTPCredential" in window) {
        const ac = new AbortController()

        if (navigator.credentials && navigator.credentials.get)
          navigator.credentials
            .get({
              // @ts-ignore
              otp: { transport: ["sms"] },
              signal: ac.signal,
            })
            .then((otp: any) => {
              setCode(otp.code)
            })
            .catch((err) => {
              console.log(err)
            })
      }
      setIsNeedOtp(false)
    }
  }, [isNeedOtp])

  return (
    <>
      <h2 className={styles.title}>{t("confirm")}</h2>
      <p className={styles.txt}>{isEmail ? t("authTextEmail2") : t("authText2")}</p>

      <div className={clsx("modal-input", styles.inputWrap, isErrCode && isShowError && "error")}>
        <div className={`input-label`}>{t("enterCode")}</div>
        <input
          placeholder="0000"
          onChange={checkCodeValueAndErrors}
          disabled={seconds === 0 || isErrCode === 3}
          ref={inp2}
          onKeyDown={(e: any) => (e.key === "Enter" ? loginWithCode() : null)}
          className={styles.input}
          autoFocus
          maxLength={4}
          type="text"
          autoComplete="one-time-code" // Установка autoComplete
          inputMode="numeric"
          value={code}
        />
        {/* <PatternFormat
          format="####"
          customInput={CustomInput}
          allowEmptyFormatting
          mask=""
          placeholder="0000"
          onChange={checkCodeValueAndErrors}
          disabled={seconds === 0 || isErrCode === 3}
          getInputRef={inp2}
          onKeyDown={(e: any) => (e.key === "Enter" ? loginWithCode() : null)}
          className={styles.input}
          autoFocus
          autoComplete="one-time-code"
        /> */}
        {isShowError && (
          <div className={`error-text`}>
            {isErrCode === 1
              ? t("enterCode")
              : isErrCode === 2
              ? `${t("incorrectCode")} ${countWithErr} ${t(
                  countWithErr === 1 ? "afterIncorrectCode1" : "afterIncorrectCode",
                )}`
              : isErrCode === 3
              ? `${t("limitReachInvalCode")}`
              : isErrCode === 5
              ? `${t("userIsBlocked")}`
              : ""}
          </div>
        )}
      </div>

      {seconds === 0 && (
        <div className={styles.secondEnd}>
          <span>{t("codeTimeEnd")}</span>
          <button
            className={styles.resendBtn}
            disabled={seconds !== 0}
            onClick={() => {
              if (!isEmail && phoneNumber) getSmsByPhone()
              if (isEmail && email) getCodeByEmail()
            }}
          >
            {t("resendCode")}
          </button>
        </div>
      )}

      <div className={styles.authBottom}>
        <Button
          mode={"gray"}
          size={"sm-width"}
          txt={t("back")}
          onClick={() => {
            setCode("")
            setStep(1)
          }}
          className={styles.btnBack}
        />
        <Button
          txt={
            isErrCode === 3
              ? `${t("resend")} ${
                  seconds > 0
                    ? `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`
                    : ""
                }`
              : `${t("next")}`
          }
          icon={isErrCode === 3 || isLoginLoading || isLoginLoadingEmail ? undefined : "arrow"}
          className={styles.btnAuth}
          onClick={loginWithCode}
          disabled={
            (isErrCode && isErrCode !== 3) ||
            (isErrCode === 3 && seconds !== 0) ||
            isLoginLoading ||
            isLoginLoadingEmail ||
            isLoading ||
            !code
          }
          isLoading={isLoginLoading || isLoginLoadingEmail}
        />
      </div>
    </>
  )
}

export default AuthModalCode
