import { api } from "./index"
import { BaseResponseType, IImportantEvent } from "../../types/content"

export const importantEventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImportantEvents: builder.query<{ aEvents: IImportantEvent[]; bIsEnd: boolean; iCount: number }, void>({
      query: () => ({
        url: `public/important-events`,
        method: "GET",
      }),
      transformResponse: (
        response: BaseResponseType<{ aEvents: IImportantEvent[]; bIsEnd: boolean; iCount: number }>,
      ) => response.data,
    }),
    getImportantEvent: builder.query<BaseResponseType<IImportantEvent>, string>({
      query: (id) => ({
        url: `public/important-events/${id}`,
        method: "GET",
      }),
    }),
    markReadImportantEvents: builder.query<any, string[]>({
      query: (ids) => ({
        url: `public/important-events/mark-readed`,
        method: "POST",
        body: { ids: ids },
      }),
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        // Optimistic update: immediately add the new post to the cache
        const patchResult = dispatch(
          api.util.updateQueryData(
            // @ts-ignore
            "getImportantEvents",
            undefined,
            (draftPosts: { aEvents: IImportantEvent[]; bIsEnd: boolean; iCount: number }) => {
              draftPosts.aEvents.forEach((event) => {
                if (ids.includes(event.id)) event.isViewed = true
              })
            },
          ),
        )
        try {
          await queryFulfilled // Await server response
        } catch {
          patchResult.undo() // Revert if the mutation fails
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetImportantEventsQuery, useLazyMarkReadImportantEventsQuery } = importantEventsApi
